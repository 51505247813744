import React from 'react';

import { Layout } from '@solublestudio/gatsby-theme-soluble-source';
import HeroSection from '../components/HeroSection';
import { useTranslate } from '@solublestudio/gatsby-theme-soluble-source';

export default function NotFoundPage() {
    const translation = useTranslate();

    return (
        <Layout isDark={true} showFooter={false}>
            <HeroSection title={translation['404'].text} />
        </Layout>
    );
}
